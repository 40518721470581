import { render, staticRenderFns } from "./adviseAdd.vue?vue&type=template&id=72a46488&scoped=true&"
import script from "./adviseAdd.vue?vue&type=script&lang=js&"
export * from "./adviseAdd.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "72a46488",
  null
  
)

export default component.exports