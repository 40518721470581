<template>
  <div>
    <van-field
      readonly
      clickable
      name="picker"
      label="协同人员"
      :value="name"
      placeholder="选择协同人员"
      @click="open"
    />
    <hr
      style="width: 91.161%; size:50px; color: #9f9f9f; text-align: center; margin: 0;border: 0"
    />
    <van-popup v-model="showPicker" round position="bottom">
      <van-checkbox-group v-model="result">
        <van-cell-group>
          <van-cell
            v-for="(item, index) in withPersonOptions"
            :key="item.value"
            clickable
            :title="`${item.text}`"
            @click="toggle(index)"
          >
            <template #right-icon>
              <van-checkbox ref="checkboxes" :name="item.value" />
            </template>
          </van-cell>
        </van-cell-group>
      </van-checkbox-group>
      <div style="margin: 40px;">
        <van-button
          round
          block
          type="info"
          size="normal"
          native-type="button"
          @click="save"
        >
          提交
        </van-button>
      </div>
    </van-popup>
  </div>
</template>

<script>
  import { getWithPerson } from '@/api/base/base'

  export default {
    name: 'WithPerson',
    props: {
      cellId: {
        type: Number,
        default: null,
      },
      userId: {
        type: Number,
        default: null,
      },
    },
    data() {
      return {
        name: '',
        nameArr: [],
        showPicker: false,
        result: [],
        withPersonOptions: [],
      }
    },
    created() {
      this.getWithPersonOptions()
    },
    methods: {
      //直接点击选项而不是选择框也能起效
      toggle(index) {
        this.$refs.checkboxes[index].toggle()
      },

      //保存选项，回调返回result
      save() {
        //获取到勾选的数组
        let resultArr = []
        this.withPersonOptions.forEach((item) => {
          if (this.result.includes(item.value)) {
            resultArr.push(item)
            this.nameArr.push(item.text)
          }
        })
        this.name = this.nameArr.join('|')
        this.$emit('withPersonChange', resultArr)
        this.close()
      },

      async getWithPersonOptions() {
        const { data } = await getWithPerson({
          Cell_ID: this.cellId,
          User_ID: this.userId,
        })
        this.withPersonOptions = data
      },

      close() {
        this.nameArr = []
        this.showPicker = false
      },

      //打开弹窗
      open() {
        this.showPicker = true
        this.getWithPersonOptions()
      },

      //清空选择器
      reset() {
        this.value = []
        this.result = []
        this.name = ''
        this.nameArr = []
      },
    },
  }
</script>

<style scoped></style>
