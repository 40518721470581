<template>
  <div style="margin: 14px">
    <van-nav-bar
      title="劝导单"
      right-text="劝导记录"
      @click-right="onClickRight"
    />
    <van-form @submit="onSubmit">
      <van-field
        readonly
        clickable
        name="picker"
        label="劝导类型"
        :value="adviseTypeName"
        placeholder="选择劝导类型"
        @click="showAdvise = true"
      />
      <van-popup v-model="showAdvise" round position="bottom">
        <van-picker
          title="劝导类型"
          show-toolbar
          :columns="adviseOptions"
          value-key="text"
          :colunms-field-names="{ text: 'label' }"
          @cancel="showAdvise = false"
          @confirm="onConfirmAdvise"
        />
      </van-popup>
      <van-field
        v-if="formData.adviseTypeValue === 1"
        v-model="formData.adviseObj"
        label="劝导对象"
        placeholder="点击扫描店铺二维码"
        :rules="[{ required: true, message: '请扫描店铺获取劝导对象' }]"
        readonly
        @click="scanShop()"
      />
      <van-field
        v-else
        v-model="formData.adviseObj"
        label="劝导对象"
        placeholder="请输入劝导对象"
        :rules="[{ required: true, message: '请输入劝导对象' }]"
      />

      <!--执法类别-->
      <van-field
        readonly
        clickable
        name="picker"
        label="执法类别"
        :value="lowName"
        placeholder="选择执法类别"
        @click="showLow = true"
      />
      <van-popup v-model="showLow" round position="bottom">
        <van-picker
          title="执法类别"
          show-toolbar
          :columns="lawOptions"
          value-key="text"
          :colunms-field-names="{ text: 'label' }"
          @cancel="showLow = false"
          @confirm="onConfirmLow"
        />
      </van-popup>

      <!--执法类别-->
      <van-field
        readonly
        clickable
        name="picker"
        label="处理结果"
        :value="handleName"
        placeholder="选择处理结果"
        @click="showHandle = true"
      />
      <van-popup v-model="showHandle" round position="bottom">
        <van-picker
          title="处理结果"
          show-toolbar
          :columns="handleOptions"
          value-key="text"
          :colunms-field-names="{ text: 'label' }"
          @cancel="showHandle = false"
          @confirm="onConfirmHandle"
        />
      </van-popup>
      <van-field label="照片上传" readonly />
      <div style="padding: 10px;background: white">
        <upload-file :limit="9" @fetch-data="getFileList" />
      </div>
      <team ref="team" :team-id="formData.teamID" @teamChange="teamChange" />
      <team-cell
        ref="cell"
        :team-id="formData.teamID"
        :cell-id="formData.cellID"
        @teamCellChange="teamCellChange"
      />
      <cell-person
        ref="person"
        :cell-id="formData.cellID"
        :person-id="formData.personID"
        @cellPersonChange="cellPersonChange"
      />
      <with-person
        ref="withPerson"
        :cell-id="formData.cellID"
        :user-id="formData.personID"
        @withPersonChange="withPersonChange"
      />
      <div style="margin: 16px;">
        <van-button round block type="info" native-type="submit">
          提交
        </van-button>
      </div>
    </van-form>
  </div>
</template>

<script>
  import dd from 'gdt-jsapi'
  import store from '@/store'
  import Base64 from '@/util/Base64'
  import Team from '@/components/Team'
  import TeamCell from '@/components/TeamCell'
  import CellPerson from '@/components/CellPerson'
  import UploadFile from '@/components/UploadFile'
  import WithPerson from '@/components/WithPerson'
  import { Toast } from 'vant'
  import { getOptions, getShopInfo } from '@/api/base/base'
  import { addAdvise } from '@/api/workBench/advise/advise'
  import { Emas } from '@/assets/js/Emas'

  export default {
    name: 'AdviseAdd',
    components: { UploadFile, Team, TeamCell, CellPerson, WithPerson },
    data() {
      return {
        fileList: [],
        showAdvise: false,
        adviseTypeName: '店铺', //劝导类型名称
        adviseObjPlace: '',

        showLow: false, //执法类型
        lowName: '劝导', //执法类型

        showHandle: false,
        handleName: '警告',

        withPersonArr: [], //协同人员数组
        formData: {
          streetID: '', //街道ID
          shopID: 0, //店铺ID
          adviseType: '店铺', //劝导类型
          adviseTypeValue: 1, //劝导类型值
          adviseObj: '', //劝导对象
          lowType: null, //执法类别
          handleType: null, //处理结果
          teamID: null,
          cellID: null,
          personID: null,
          User_ID: 0,
          filePath: '',
          withPerson: '',
          coordinate: '',
        },
        //劝导类型
        adviseOptions: [
          {
            text: '店铺',
            value: 1,
          },
          {
            text: '其它',
            value: 2,
          },
        ],
        //执法类型
        lawOptions: [],
        //处理类型
        handleOptions: [],
      }
    },
    created() {
      let userInfo = JSON.parse(store.getters['user/userInfo'])
      let teamInfo = JSON.parse(store.getters['user/teamInfo'])
      this.formData.User_ID = Number(userInfo.User_ID)
      this.formData.teamID = Number(teamInfo.Team_ID)
      this.formData.cellID = Number(teamInfo.Cell_ID)
      this.formData.personID = Number(userInfo.User_ID)
      this.formData.shopID =
        this.$route.query.shopID === undefined
          ? 0
          : Base64.decode(this.$route.query.shopID)

      this.formData.adviseObj =
        this.$route.query.shopName == undefined
          ? ''
          : Base64.decode(this.$route.query.shopName)

      this.fetchLowOptions()
      this.fetchHandleOptions()
      this.getLocation()
      let staffName = userInfo.Staff_Name
      let userId = userInfo.User_ID
      Emas(
        staffName,
        userId,
        'adviseAdd',
        '城管-劝导新增',
        'zzdcg.yy.gov.cn/adviseAdd'
      )
    },

    methods: {
      async onSubmit() {
        this.formData.filePath =
          this.fileList.length > 0 ? this.fileList.join('|') : ''
        console.log(this.formData)

        this.formData.withPerson =
          this.withPersonArr.length > 0
            ? this.handleWithPerson(this.withPersonArr).join('~')
            : ''

        Toast.loading({
          duration: 3000, // 持续展示 toast
          message: '正在提交中...',
          forbidClick: true,
        })
        const { data } = await addAdvise(this.formData)
        Toast.clear()
        await this.onClickRight()
      },

      getFileList(val) {
        this.fileList = val
      },

      //扫描店铺二维码
      scanShop() {
        //if (this.formData.adviseObj === '') {
        window.dd
          .scan({
            type: 'qr',
          })
          .then((res) => {
            let streetID = res.text.split('=')[1]
            this.fetchShopInfo(streetID)
          })
          .catch()
        /* let res = {
            text:
              'http://mqsb.yy.gov.cn/WX_GZH/Check_WX.aspx?ID=cbcab1007dba3021',
          }
          this.formData.streetID = res.text.split('=')[1]*/
        //}
      },

      async fetchShopInfo(streetID) {
        this.formData.streetID = streetID
        Toast.loading({
          duration: 3000, // 持续展示 toast
          message: '正在加载中...',
          forbidClick: true,
        })
        const { data } = await getShopInfo({
          Street_ID: streetID,
        })
        this.formData.adviseObj = data.Shop_Name
        this.formData.shopID = data.Shop_ID
        Toast.clear()
        if (this.formData.shopID === 0)
          Toast('当前地址暂未绑定店铺，请重新扫码')
      },

      //劝导类型
      onConfirmAdvise(value) {
        this.formData.adviseObj = ''
        this.adviseTypeName = value.text
        this.formData.adviseType = value.text
        this.formData.adviseTypeValue = value.value
        this.showAdvise = false
      },

      //执法类别
      onConfirmLow(value) {
        this.lowName = value.text
        this.formData.lowType = value.value
        this.showLow = false
      },

      //处理结果
      onConfirmHandle(value) {
        this.handleName = value.text
        this.formData.handleType = value.value
        this.showHandle = false
      },

      //中队选择
      teamChange(val) {
        this.$refs['cell'].reset()
        this.$refs['person'].reset()
        this.$refs['withPerson'].reset()
        this.formData.teamID = val
      },

      //网格选择
      teamCellChange(val) {
        this.$refs['person'].reset()
        this.$refs['withPerson'].reset()
        this.formData.personID = 0
        this.formData.cellID = val
      },

      //人员选择
      cellPersonChange(val) {
        this.$refs['withPerson'].reset()
        this.formData.personID = val
      },

      //协同人员选择
      withPersonChange(val) {
        this.withPersonArr = val
      },

      //跳转到劝导单
      onClickRight() {
        this.$router.push({
          path: '/advise',
        })
      },

      //获取执法类型
      async fetchLowOptions() {
        const { data } = await getOptions({ Property_ID: 77 })
        this.lawOptions = data
        this.formData.lowType = data[0].value
      },

      //获取处理结果
      async fetchHandleOptions() {
        const { data } = await getOptions({ Property_ID: 78 })
        this.handleOptions = data
        this.formData.handleType = data[0].value
      },

      async getLocation() {
        window.dd
          .getGeolocation({
            targetAccuracy: 100,
            coordinate: 0,
            withReGeocode: false,
            useCache: true, //默认是true，如果需要频繁获取地理位置，请设置false
          })
          .then((res) => {
            this.formData.coordinate = res.longitude + ',' + res.latitude
          })
          .catch((err) => {})
      },

      handleWithPerson(withArr) {
        let arr = []
        if (withArr.length > 0) {
          withArr.forEach(function(item) {
            let str = ''
            str = item.value + '|' + item.text
            arr.push(str)
          })
        }
        return arr
      },
    },
  }
</script>

<style scoped></style>
